<script lang="ts" setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import { computed, useDevice } from '#imports';

interface IModalProps {
  modelValue: boolean;
  title?: string;
  initialFocus?: HTMLElement | null;
  mode?: 'fullscreen' | 'bottom' | 'top' | 'default';
  animated?: boolean;
  dismissable?: boolean;
  hideContentWhileAnimating?: boolean;
}

interface Emits {
  (e: 'update:modelValue', value: boolean): void;
}

const props = withDefaults(defineProps<IModalProps>(), {
  mode: 'default',
  animated: true,
  dismissable: true,
});

const emit = defineEmits<Emits>();

const { isMobile, isSafari } = useDevice();

const showContent = ref(!props.hideContentWhileAnimating);

const transitions = computed(() => {
  if (!props.animated) {
    return {};
  }

  if (props.mode === 'fullscreen') {
    return {
      enter: 'duration-300 ease-out',
      'enter-from': 'translate-y-full',
      'enter-to': 'translate-y-0',
      leave: 'duration-75 ease-in',
      'leave-from': 'translate-y-0',
      'leave-to': 'translate-y-full',
    };
  }

  if (props.mode === 'bottom') {
    return {
      enter: 'duration-300 ease-out',
      'enter-from': 'translate-y-full',
      'enter-to': 'translate-y-0',
      leave: 'duration-75 ease-in',
      'leave-from': 'translate-y-0',
      'leave-to': 'translate-y-full',
    };
  }

  if (props.mode === 'top') {
    return {
      enter: 'duration-300 ease-out',
      'enter-from': '-translate-y-full',
      'enter-to': 'translate-y-0',
      leave: 'duration-75 ease-in',
      'leave-from': 'translate-y-0',
      'leave-to': '-translate-y-full',
    };
  }

  return {
    enter: 'duration-300 ease-out',
    'enter-from': 'opacity-0 scale-95',
    'enter-to': 'opacity-100 scale-100',
    leave: 'duration-75 ease-in',
    'leave-from': 'opacity-100 scale-100',
    'leave-to': 'opacity-0 scale-95',
  };
});

watch(
  () => props.modelValue,
  (modelValue) => {
    const bodyElement = document.querySelector('body');

    if (bodyElement && isMobile && isSafari && props.mode === 'fullscreen') {
      if (modelValue) {
        bodyElement?.classList.add('fixed');
        bodyElement?.classList.add('top-0');
        bodyElement?.classList.add('w-full');
      } else {
        bodyElement?.classList.remove('fixed');
        bodyElement?.classList.remove('top-0');
        bodyElement?.classList.remove('w-full');
      }
    }
  },
);

function closeModal() {
  emit('update:modelValue', false);
}

function onShow() {
  if (props.hideContentWhileAnimating) {
    showContent.value = true;
  }
}

function onHide() {
  if (props.hideContentWhileAnimating) {
    showContent.value = false;
  }
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <TransitionRoot
    :show="modelValue"
    as="template"
    appear
  >
    <Dialog
      as="div"
      class="relative z-50"
      :initial-focus="initialFocus"
      @close="closeModal"
    >
      <div
        v-if="mode !== 'fullscreen'"
        class="fixed inset-0 bg-black bg-opacity-40"
      />

      <div
        class="fixed inset-0"
        :class="{ 'overflow-y-auto': mode !== 'fullscreen' }"
      >
        <div
          class="h-full"
          :class="{ 'flex items-center justify-center p-15': mode === 'default', 'flex items-end': mode === 'bottom' }"
        >
          <TransitionChild
            as="template"
            v-bind="transitions"
            @after-enter="onShow"
            @after-leave="onHide"
          >
            <DialogPanel
              :class="[
                mode === 'fullscreen'
                  ? 'h-screen-auto'
                  : mode === 'top'
                  ? 'align-middle'
                  : 'max-w-2xl rounded align-middle shadow-xl',
                'relative w-full bg-white text-left',
                $attrs.class,
              ]"
            >
              <slot name="header">
                <ApzHeader
                  v-if="mode === 'fullscreen' || mode === 'top'"
                  :title="props.title"
                  variant="solid"
                >
                  <template #postfix>
                    <ApzButton
                      variant="clear"
                      class="px-15 w-[30px] !h-[30px]"
                      @click="closeModal"
                    >
                      <ApzIcon
                        icon="times"
                        class="text-white text-medium"
                        fixed-width
                      />
                    </ApzButton>
                  </template>
                </ApzHeader>

                <template v-else>
                  <slot
                    v-if="title || $slots.title"
                    name="title"
                  >
                    <DialogTitle
                      as="div"
                      :class="['font-bold p-15 sm:p-20', isMobile ? 'text-base' : 'text-medium']"
                    >
                      {{ title }}
                    </DialogTitle>
                  </slot>

                  <ApzButton
                    v-if="dismissable"
                    variant="clear"
                    class="absolute top-10 right-10 px-10 w-[30px] !h-[30px]"
                    @click="closeModal"
                  >
                    <ApzIcon
                      icon="times"
                      class="text-gray-darkest"
                      size="sm"
                      fixed-width
                    />
                  </ApzButton>
                </template>
              </slot>

              <slot v-if="showContent" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
